<template>
  <base-bg>
    <div>
      <top-nav-bar></top-nav-bar>

      <section class="page with-footer">
        <div class="container">
          <base-list-title :title="title"></base-list-title>
          <base-search
            @updatedSearchTerm="updateSearchTerm"
            :searchTerm="searchTerm"
          ></base-search>
          <div
            class="my-3 row justify-content-around"
            aria-label="Tags zur Filterung der Suchergebnisse"
          >
            <base-tag
              v-for="tag in recipeTags"
              :key="tag.id"
              :title="tag.title"
              :active="tag.isActive"
              :width="tagWidth"
              @click.native="recipeTagClicked(tag.id)"
            ></base-tag>
          </div>
          <base-list
            :items="elements"
            :page="$routeNames.routeRecipePage"
            :showLikes="true"
          ></base-list>
        </div>
      </section>
    </div>
  </base-bg>
</template>

<script>
import filteredItems from "../../assets/helpers/filter.js";
import converter from "../../assets/helpers/converter.js";
import { mapGetters, mapActions } from "vuex";
export default {
  inject: ["activePage"],
  props: ["searchLink"],
  data() {
    return {
      recipeTags: [
        { id: 0, title: "Vegetarisch", isActive: false },
        { id: 1, title: "Vegan", isActive: false },
        { id: 2, title: "~20 min", isActive: false },
        { id: 3, title: "~40 min", isActive: false },
        { id: 4, title: "~60 min", isActive: false },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters(["getRecipes", "getRecipeSearch"]),
    elements() {
      let items = this.getRecipes;
      console.log(items);

      // search Term filters ALL Recipes
      if (this.searchTerm != "") {
        items = filteredItems(items, this.searchTerm, [
          "path",
          "author",
          "cleanLink",
          "source",
        ]);
        items = this.filterRecipeTags(items);
        return items;
      }

      // get seasonal Recipes
      const season = converter.getSeasonName();
      const seasonalItems = items.filter((item) => {
        return item.seasonNames.includes(season);
      });
      items = seasonalItems;

      // tags filter seasonal Recipes
      items = this.filterRecipeTags(items);

      return items;
    },
    title() {
      let string = "";
      if (this.searchTerm == "") {
        let season = converter.getSeasonName();
        season = season == "Frühling" ? "Frühlings" : season;
        string = `Das sind unsere <strong>${season}</strong>-Rezepte - über die Suche findet Ihr
       auch die Rezepte für andere Jahreszeiten`;
      } else {
        string = `Dein Suchbegriff ist in folgenden Rezepten enthalten - unabhängig von den Jahreszeiten`;
      }

      return string;
    },
    tagWidth() {
      if (this.$screen.width >= 768) {
        return 120;
      } else {
        return null;
      }
    },
    areAnyFiltersActive() {
      let anyTagIsActive = false;
      for (const tag of this.recipeTags) {
        if (tag.isActive) {
          anyTagIsActive = true;
        }
      }

      if (this.searchTerm == "" && !anyTagIsActive) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(["saveRecipeSearch"]),
    recipeTagClicked(index) {
      this.recipeTags[index].isActive = !this.recipeTags[index].isActive;
      if (this.recipeTags[index].isActive) {
        if (index == 0) {
          this.recipeTags[1].isActive = false;
        } else if (index == 1) {
          this.recipeTags[0].isActive = false;
        }
        for (let tag of this.recipeTags) {
          if (tag.id > 1 && tag.id != index) {
            this.recipeTags[tag.id].isActive = false;
          }
        }
      }
    },
    saveSearch() {
      let obj = { recipeTags: this.recipeTags, searchTerm: this.searchTerm };
      this.saveRecipeSearch(obj);
    },
    filterRecipeTags(items) {
      const tags = this.recipeTags;
      if (tags[0].isActive) {
        items = items.filter((item) => item.tags.includes(2));
      }
      if (tags[1].isActive) {
        items = items.filter((item) => item.tags.includes(1));
      }
      if (tags[2].isActive) {
        items = items.filter((item) => item.duration <= 20);
      }
      if (tags[3].isActive) {
        items = items.filter((item) => item.duration <= 40);
      }
      return items;
    },

    updateSearchTerm(val) {
      this.searchTerm = val;
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routeRecipesList;
    if (this.searchLink) {
      this.searchTerm = this.searchLink;
      this.saveSearch();
    } else if (this.getRecipeSearch) {
      this.recipeTags = this.getRecipeSearch.recipeTags;
      this.searchTerm = this.getRecipeSearch.searchTerm;
    }
  },
  beforeRouteLeave(_, __, next) {
    this.saveSearch();
    next();
  },
};
</script>

<style scoped>
.m-90 {
  width: 90vw;
}
</style>
